import React, { Component , createRef, useState } from 'react'
import './navbar.scss'
import MediaQuery from 'react-responsive';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import { delay } from 'lodash';
class Navbar extends Component {
    constructor(props){
        super(props)
        this.activePosition = this.activePosition.bind(this)
        this.close = this.close.bind(this)
        this.toggle = this.toggle.bind(this)
        this.moveTo = this.moveTo.bind(this)
        this.offset = this.offset.bind(this)
        this.state = {
            activeLine: 0,
            activeNav:true,
            initTransparent:true,
            closeMore:false,
            toggle:false,
            links:[
                {index:0,title:"Proyectos",link:"/",show:false,initTransparent:false},
               
            ]
        }
        this.nav = React.createRef();
        
    }
    componentDidUpdate(props,prevpros){
        if(this.props.location.pathname != '/'){
           window.scrollTo(0,0)
       } 
       
    }
    componentDidMount(){
        // window.scrollTo(0,0)
        let location = this.state.links.filter((item,index)=>{
            let data;

            if( window.location.href.includes(item.link)){
                this.setState({activeLine:item.index,initTransparent:item.initTransparent,activeNav:item.initTransparent == true ? false : true})
                data = item.index
            }
            return data            
        })
        window.onscroll = () => {
            if(this.state.initTransparent == true){
                window.scrollY == 0 ? this.setState({activeNav:false}): this.setState({activeNav:true});
            }
        }
    }
    activePosition(e,position,options,item){

        this.setState({activeLine:item.index,activeNav:item.initTransparent ? false : true})
        if(options.move == true){
            this.moveTo(options.from,options.to)
        }
        if(item.initTransparent != true){
            window.onscroll = ()=>{}
        }
        if(item.initTransparent == true){
            window.onscroll = () => {
                window.scrollY == 0 ? this.setState({activeNav:false}): this.setState({activeNav:true});
            }
        }
   
    }
    close(e){
        this.setState({closeMore:true})
    }
    toggle(e){
        this.setState({toggle:!this.state.toggle})
        // this.state.toggle == false ? document.querySelector("html").style="overflow-y: hidden;" : document.querySelector("html").style="overflow-y: scroll;" 
    }
    moveTo = (elementFrom,elementTo)=>{
        try {
            let navHeight = this.nav.current?.clientHeight || 0;
            if(window.location.pathname != '/'){
                this.props.history.push('/');
                let delay = setInterval(()=>{
                    if(document.querySelector(elementTo) && document.querySelector("#banner-hands") && navHeight){
                        window.scrollTo(0,this.offset(elementTo).top-(navHeight+40))
                        this.setState({toggle:false})
                        clearInterval(delay)
                    }
                },300)
            }
            if(document.querySelector(elementTo) && navHeight){
                window.scrollTo(0,this.offset(elementTo).top-(navHeight+40))
                this.setState({toggle:false})
            }
            
        } catch (error) {
            console.log(error)
        }
    }
    offset(e){
        let element;
        if(typeof e == "object"){
            element = e
        }else{
            element = document.querySelector(e)
        }
        if(element){
            try { 
                let top = element.getBoundingClientRect().top + window.scrollY 
                let left = element.getBoundingClientRect().left
                return {top:top,left: left}
            }catch(err){
                return "dosnt exist"
            }
        }else{
        }
    }
    render() {
        return (
            <nav ref={this.nav} id="nav" className={`navbar ${this.state.activeNav == true ? "active" : ""}`}>
                <div className="container content-nav">
                    <Link to="/"className="brand" onClick={()=>{window.scrollTo(0,0)}}>
                        <img className="logo-white" alt='Cosapi' src={require("../../assets/images/icons/brand-cosapi.png")}></img>
                        <img className="logo-sky" alt='Cosapi' src={require("../../assets/images/icons/brand-cosapi-blue.png")}></img>
                    </Link>
                    <OptionNav options={this.state} position={this.activePosition} moveTo={this.moveTo}></OptionNav>
                </div>
            </nav>
        )
    }
}
export default withRouter(Navbar)
class OptionNav extends Component{
    constructor(props){
        super(props)
    }
    render(){
        return(
        <div className={`container-link ${this.props.options.toggle ? "active" : ""}`}>
        <div className="links"> 
            <div className="link-buttons">
                <a className='link button white mr-1' href={require("../../assets/files/Brochure Multiproducto Cosapi Inmobiliaria.pdf")} target="_blank">
                    <span className="text-link cart c-gray">
                        Descargar Brochure
                    </span>
                </a>
                <div className={`link button cotiza-depa`}
                    onClick={(e)=>{this.props.moveTo(".link.button","#contacto")}} 
                >
                    <div  className="content-text cart">
                        <span className="text-link cart">¡Cotiza aquí!</span>
                        <span className="barra-line"></span>
                    </div>
                </div>
            </div>
        </div>
        </div>
    )
    }
}