import { BaseEntity } from "./baseEntity.entity";
import { Type } from "./type.entity";
import { SperantService } from "../component/common/Http/SperantService";

export class Project extends BaseEntity {
    id
    /** @type {String} */
    name
    
    ubication = {
        department: "",
        province: "",
        district: "",
        address: "",
        latitud: 0,
        longitud: 0
    }

    phases = []

    /** @type {Array<Type>} */
    types = []
    /** @type {Array<Type>} */
    flatTypes = []
    /** @type {Int} */
    templateId = null;

    #isReady = false
    
    #sperantService = new SperantService()

    // constructor({id, leadCreationAllowed, environment, template_id, name, ubication, phases, source_ids, input_channels}){
    constructor(data){
        super()
        this._parseAttrs(this, data)
        this.#sperantService.environment(this.environment)
        // this.#fetchProjectData()
    }

    // * typesReady() {

    // }

    get isReady(){
        return new Promise((resolve, reject)=>{
            let counter = 1
            while (counter<2000) {
                if (this.#isReady == true) {
                    resolve(this.#isReady)
                    break
                }
                counter++
                console.log(this.#isReady)
            }
        });
    }

    get isOnSale() {
        return this.phases?.[0]?.stage !== "Vendido"
    }

    get phasesWithId(){
        return this.phases.filter(phase => phase.id !== undefined)
    }

    get flatsAvailable(){
        return this.flatTypes.filter(f => f.isAvailable)
    }

    get availableBedrooms(){
        return [
          ...new Set(this.flatsAvailable.map(flat => flat.numBedroom))
        ].sort()
    }

    /**
     * @param {string} phase
     */
    bedroomsAvailableByPhase(phase){
        console.log(phase)
        return [
          ...new Set(
            this.getFlatsBy("fase", phase).map(flat => flat.numBedroom)
          )
        ].sort()
    }

    /**
     * 
     * @param {string} field 
     * @param {*} value 
     * @returns 
     */
    getFlatsBy(field, value){
        return this.flatsAvailable.filter(flat => flat[field] == value)
    }

    async #fetchProjectData(){
        if(!this.id) return
        
        let project_ids = this.phasesWithId.length
                        ? this.phasesWithId.map((f) => f.id)
                        : [this.id]
        
        const promises = [];
        for (const id of project_ids) promises.push(this.#sperantService.projects(id))

        const results = await Promise.all(promises)

        
        // const results = await Promise.all(promises)
        // results.map((units) =>{
        //     const flats = [...new Map(units.map(u => ({...u.type, subdivision: u.subdivision || {id: null, name: this.phasesWithId.map((f) => f.id)}}))
        //         .map(item => [item['id'], item])).values()]
    
        //     console.log(this.name, units, flats)
        // })
        
        
        const types = []
        results.map(({data: {project}}) =>{
            const ttypes = project.types.map((type) => {
                type.fase = this.phasesWithId.find((p) => p.id == project.id)?.name || ""
                type.is_available = type.units_available > 0
                // if(this.name == "Nesta" || this.name == "Épiqe") type.isAvailable = true
                const tmp = {}
                this._parseAttrs(tmp, type);
                return tmp
            }).filter(t =>
                // t.image &&
                // !t.image.match(/\.pdf|\.doc/i) &&
                !t.name.match(/sotano/i) &&
                t.numBedroom
            );

            [].push.apply(types, ttypes);
        })
        this.flatTypes = types

        this.#isReady = true
    }

    async #fetchUnits(project_id = null){
        console.log("project_id", project_id)
        const params = {property_type_id: "28,12,16,15,4,14", commercial_status_id:1}
        const promises = []
        
        const {data: recon} = await this.#sperantService.units(project_id, params)
        const total_units = recon.units

        for (let index = 2; index < recon.meta.pagination.total_pages; index++)
            promises.push(this.#sperantService.units(project_id, {...params, page: index}));

        const responses = await Promise.all(promises)
        responses.map(({data: {units}}) => [].push.apply(total_units, units))
        
        total_units.forEach( u => u.project_id = project_id);

        return total_units;
    }
}

