import { useEffect, useState } from "react";
import { checkableBoolProps, FormContainer, setInputProps } from "../../component/common/forms/Form";
import { MultiplyInterestedSchema, ReferecialSchema } from '../../component/common/forms/constraints/ValidatonSchemas'
import { onChangeInput, options } from "./script";
import { dataInicio, PROJECT_CARDS } from "../../data/inicio";
import { PROJECTS, PROJECTS_DATA, PROJECT_PHASES } from '../../data/projects';
import MediaQuery from 'react-responsive';
import OwlCarousel from "react-owl-carousel2"
import $ from 'jquery';
import { SperantService } from "../../component/common/Http/SperantService";
import Swal from "sweetalert2";
import { useField } from "formik";
import 'lightgallery/dist/css/lightgallery.css';
import 'lightgallery/dist/js/lightgallery';
import 'lightgallery/dist/js/lightgallery-all';
import { useDispatch, useSelector } from "react-redux";
import { changeName } from "./multiproductoSlice";
import { LeadService } from "../../component/common/Http/LeadService";
const events = {
    onDragged: function(event) {},
    onChanged: function(event) {},
}

const generateId =({type})=>{
    const typeid = {
        number : (new Date()).getTime(),
        string : Math.random().toString(36).substr(2, 18)
    }
    const typeIdDefault = typeid.string;
    return typeid[type] || typeIdDefault
}
export const FormMultiproducto = (props) => {
    const dispatch = useDispatch();
    const projectSelected = useSelector((state) => state.multiproductoSlice.nameProject)
    const [activeModal, setActiveModal] = useState(false)
    const [generateID, setGenerateID] = useState(generateId({type:"number"}))
    const [isReady, setReady] = useState(false)
    const [project, setProject] = useState(PROJECTS[0]);

    const projectSelect = [
        {name:"Midgo",dorms:[{value:"2",text:"2 Dorms"},{value:"3",text:"3 Dorms"}],},
        {name:"Momen",dorms:[{value:"2",text:"2 Dorms"},{value:"3",text:"3 Dorms"}],},
        {name:"Ativa - Fase 1",dorms:[{value:"1",text:"1 Dorm"},{value:"2",text:"2 Dorms"},{value:"3",text:"3 Dorms"}],},
        {name:"Ativa - Fase 2",dorms:[{value:"2",text:"2 Dorms"},{value:"3",text:"3 Dorms"}]},
        {name:"Épiqe",dorms:[{value:"2",text:"2 Dorms"},{value:"3",text:"3 Dorms"}]},
        {name:"Muvin",dorms:[{value:"1",text:"1 Dorm"},{value:"2",text:"2 Dorms"},{value:"3",text:"3 Dorms"}]},
        {name:"Nesta - Fase 4",dorms:[{value:"1",text:"1 Dorm"},{value:"2",text:"2 Dorms"},{value:"3",text:"3 Dorms"}]},
        {name:"Nesta - Fase 5",dorms:[{value:"1",text:"1 Dorm"},{value:"2",text:"2 Dorms"},{value:"3",text:"3 Dorms"}]},
        // {name:"Sente",dorms:[{value:"2",text:"2 Dorms"},{value:"3",text:"3 Dorms"}]},
    ]
    const initValuesForm = {
            project: "",
            dorms: "",
            fname: "",
            lname: "",
            telephone: "",
            email: "",
            time_to_contact: "",
            terms: false,
    }
    const [initialValues,setInitialValues] = useState(initValuesForm)

    const onError = e =>{
        Swal.fire({
            title: '',
            text: '¡Error! Intentelo más tarde',
            icon: 'error',
            confirmButtonText: 'Aceptar',
            confirmButtonColor: 'var(--primary)'
        })
    }
    const changeProject =(e)=>{
        let selectValue = e.target.value;
        let select_dorm = document.getElementsByName("dorms")[0]
        dispatch(changeName(selectValue))
    }

    const submitForm = (values, { setSubmitting, resetForm }) => {
        const project = PROJECTS.find(p => p.name == values.project.split(" - ")[0])
        console.log(project)
        
        Object.assign(values, {
            phase: values.project.split(" - ")[1] || "",
            input_channel_id: project.inputChannels.web,
            source_id: project.sourceIds.multiproducto
        })
        
        const leadService = new LeadService()
        leadService
            .setProject(project)
            .setLeadData(values)
            .save().then(client =>{
                setActiveModal(true)
                resetForm()
                setGenerateID(generateId({type:"number"}))
            })
            .catch(e=>this.onError)
            .finally(_=>setSubmitting(false))
    }

    const closeModal = (e) => {
        if(e.target.className.includes('type-popup')) setActiveModal(false)
    }

    /**
     * 
     * @param {Event} obj
     */
    const handleProjectChange = ({target: {value}}, values) => {
        const [name, phase] = value.split(",")
        setProject(PROJECTS.find(p => p.name === name) || PROJECTS[0])
    }
    const onchange = (e,form)=>{
        console.log(e)
        form.handleChange(e)
    }
    useEffect(() => {
        setTimeout(() => {
            setReady(true)
        }, 2000);
    }, [])

    if (!isReady) {
        return <></>
    }
    
    return(
        <section className='information container'>
            <div className='formulario' key={generateID}>
                <FormContainer
                    initialValues={initialValues}
                    validationSchema={MultiplyInterestedSchema}
                    onSubmit={submitForm}
                >
                    {form => {const {values, errors, handleSubmit, isSubmitting} = form;
                    return(
                    <form className="form" id="contacto" onSubmit={handleSubmit}>
                        <div className={`type-popup popup ${activeModal ? "active" : ""}`} onClick={(e)=>{closeModal(e)}}>
                            <div className="content-popup">
                                <div className="btn-close type-popup" onClick={(e)=>{closeModal(e)}}><img src={require("../../assets/images/icons/btn-close.svg").default} alt=""></img></div>
                                <img className="background desktop" src={require("../../assets/images/icons/background-modal.png")} alt=""></img>
                                <img className="background movil" src={require("../../assets/images/icons/background-modal-movil.png")} alt=""></img>
                                <img className="icon-check" src={require("../../assets/images/icons/check_icon.svg").default} alt=""></img>
                                <span className="title">¡Gracias!</span>
                                <span className="sub-title ">Dentro de poco un asesor se pondrá en contacto contigo.</span>
                            </div>
                        </div>
                        <div className='personal'>
                            <h2 className='title text-center c-white'>¡Estás a un paso de cumplir tus sueños!</h2>
                            <span className='sub-title c-white text-center mb-2'>Agenda una asesoría personalizada</span>
                            <div className="content-inputs">
                                {/* {
                                    console.log(form.errors)
                                } */}
                                <div className='content-input middle' onChange={(e)=>{changeProject(e)}}>
                                    <select {...setInputProps("project", "", {...form, handleChange: e =>{form.handleChange(e);handleProjectChange(e, values)}})}>
                                        <option value="">Proyecto*</option>
                                        {
                                            projectSelect.map((e)=>{
                                                return(
                                                    <option value={e.name}>{e.name}</option>
                                                )
                                            })
                                        }
                                    </select>
                                    <div className='select-down mask'>
                                    </div>
                                </div>
                                <div className='content-input middle'>
                                    <select {...setInputProps("dorms", "",form)}>
                                        <option value="">N° de dormitorios*</option>
                                        {
                                            projectSelect.filter((e)=>{
                                                if(e.name == projectSelected) return e;
                                            })[0]?.dorms.map((e)=>{
                                                return (
                                                    <option value={e.value}>{e.text}</option>
                                                )
                                            })
                                        }
                                    </select>
                                    <div className='select-down mask'>
                                    </div>
                                </div>
                                <div className='content-input middle'>
                                    <input placeholder="Nombre*" {...setInputProps("fname", "", {...form, handleChange: e =>{form.handleChange(e);}})} defaultValue={initialValues.fname} ></input>
                                </div>
                                <div className='content-input middle-2' onChange={(e)=>{onchange(e,form)}}>
                                    <input placeholder="Apellidos*" {...setInputProps("lname", "", form)} defaultValue={initialValues.lname}></input>
                                </div>
                                <div className='content-input middle'>
                                    <input placeholder="Teléfono*" {...setInputProps("telephone", "", form)} defaultValue={initialValues.telephone}></input>
                                </div>
                                <div className='content-input middle-2'>
                                    <input placeholder="Correo Electrónico*" {...setInputProps("email", "", form)} defaultValue={initialValues.email}></input>
                                </div>
                                <div className='content-input'>
                                    <select {...setInputProps("time_to_contact", "", {...form, handleChange: e =>{form.handleChange(e);handleProjectChange(e, values)}})}>
                                        <option value="">*Selecciona un horario para que un asesor te pueda llamar</option>
                                        <option value="10:00 am - 11:00 am">10:00 am - 11:00 am</option>
                                        <option value="11:00 am - 12:00 pm">11:00 am - 12:00 pm</option>
                                        <option value="12:00 pm - 1:00 pm">12:00 pm - 1:00 pm</option>
                                        <option value="1:00 pm - 2:00 pm">1:00 pm - 2:00 pm</option>
                                        <option value="1:00 pm - 1:00 pm">1:00 pm - 1:00 pm</option>
                                        <option value="2:00 pm - 3:00 pm">2:00 pm - 3:00 pm</option>
                                        <option value="3:00 pm - 4:00 pm">3:00 pm - 4:00 pm</option>
                                        <option value="4:00 pm - 5:00 pm">4:00 pm - 5:00 pm</option>
                                        <option value="5:00 pm - 6:00 pm">5:00 pm - 6:00 pm</option>
                                        <option value="6:00 pm - 7:00 pm">6:00 pm - 7:00 pm</option>
                                    </select>
                                    <div className='select-down mask'>
                                    </div>
                                </div>
                            </div>
                            <div className="politicas">
                                <input id="terms" type="checkbox" {...checkableBoolProps("terms", "", form)} defaultValue={initialValues.terms}></input>
                                <label htmlFor="terms" className="label-accept"><div className="check"></div></label>
                                <span className="terms c-white">Acepto las <a target="_blank" className="c-white" href={require("../../assets/files/Politica-de-privacidad.pdf")}>políticas de privacidad</a></span>
                            </div>
                            <button type="submit" className="btn-submit btn-radio b-white c-yellow" disabled={isSubmitting}>{isSubmitting ? 'Enviando...' : 'Enviar'}</button>
                        </div>
                    </form>
                    )}}
                </FormContainer>
            </div>
        </section>
    )
}

function SliderForm({project, formValues}){
    useEffect(()=>{
        $(".owl-carousel").lightGallery({
            selector: '.lupa img',
            thumbnail:true,
            animateThumb: false,
            showThumbByDefault: false,
            subHtmlSelectorRelative: true,
            rotate:false,
            actualSize:false,
            download:true,
            pinterest:false,
            googlePlus:false,
            twitter:false,
        });
    },[project]);

    return(
        <OwlCarousel options={options} events={events} className={`owl-carousel active`}>
            {project.flatsAvailable.map((type, index) => 
                <div className="tipology"  key={index+"-tipology"} name={type.name} phase={type.fase}>
                    <div className='lupa'>
                        <img className='img-flat' href={type.image} src={require('../../assets/images/icons/lupa.png')} ></img>
                    </div>
                    <img className='img' loading="eager" src={type.image} alt={project.name+" - "+"Tipologia "+type.name+" - Departamentos en "+project.district}></img>
                </div>
            )}
        </OwlCarousel>
    )
}