import React, { Component } from 'react'
import './inicio.scss'
import Slider from "react-slick";
import Footer from '../../component/footer/footer';
import Card from '../../component/card/card';
import Formulario from '../../component/contact/contact';
import Displaceable from 'displaceable';
import { PROJECTS_CRM } from '../../data/projects';
import Popup from '../../component/popup/popup'
import { FacebookChat } from '../../component/common/FacebookChat';


export default class Inicio extends Component {
    constructor(props){
        super(props)
        this.changeSlider = this.changeSlider.bind(this)
        this.afterChange = this.afterChange.bind(this)
        this.moveTo = this.moveTo.bind(this)
        this.offset = this.offset.bind(this)
        this.activeModal = this.activeModal.bind(this)
        this.state = {
            cardFilter:this.props.data.cardsFilter,
            activeSlider:0,
            position:1,
            input1:0,
            input2:0,
            dorms:[],
            modal:false,
          
            settings:{
                dots: false,
                arrows:false,
                infinite: true,
                speed: 500,
                autoplay: true,
                autoplaySpeed: 3000,
                slidesToShow: 1,
                slidesToScroll: 1,
                afterChange:this.afterChange
            },
            settingsRange:{
                min:0,
                max:3,
                step:1,
                getValue:this.getValue
            }
        }
        this.$$facebookChat = new FacebookChat(
            "176165019891976", "823525821009119"
        );

        this.$$facebookChat.init();
    }
    activeModal(){
        this.setState({modal:!this.state.modal})
    }
    componentDidMount(){
        const displaceable = new Displaceable(document.getElementById('laptop'),{
            displaceFactor: .5,
            skewFactor:.5,
            resetTime: 200,
        });
       
        
    }
    getValue(e){
        if(e.name == "input1"){
            this.setState({input1:e[e.name]})
        }
        if(e.name == "input2"){
            this.setState({input2:e[e.name]})
        }
        
    }
    afterChange(e){
        this.setState({activeSlider:e})
    }
    changeSlider(e,position){
        this.slider.slickGoTo(position)
    }
    moveTo(elementFrom,elementTo, project){
        if(project == "Duplo" || project == "Prana" || project == "Velia"){
        }else{
            let navHeight = document.querySelector("#nav").clientHeight
            window.scrollTo(this.offset(elementFrom).top,this.offset(elementTo).top-(navHeight+30))
        }
        
        project = project.toLowerCase()
        const select = document.querySelector("select[name=project]")

        if(PROJECTS_CRM[project]?.leadCreationAllowed){
            select.value = project
        } else {
            select.selectedIndex = 0
        }
        select.dispatchEvent(new Event("change",{bubbles: true}))
        window.dataLayer && window.dataLayer.push({
            event: "gaEvent",
            eventCategory: "Clic banner proyecto",
            eventLabel: project,
            eventAction: "Click"
        })
        // this.setState({toggle:false})
    }
    offset(e){
        let element = document.querySelector(e)
        if(element){
            try { 
                let top = element.getBoundingClientRect().top + window.scrollY 
                let left = element.getBoundingClientRect().left
                return {top:top,left: left}
            }catch(err){
                return "doesnt exist"
            }
        }else{
        }
    }

    render() {
       
        return (
            <main className="inicio">
                {
                    // <Popup></Popup>
                }
                <section className="content_slider" id="slider-main">
                    <Slider {...this.state.settings} ref={slider => (this.slider = slider)}>
                        {
                            this.props.data.sliderMain.map((e,index)=>{
                                return(
                                    <div key={"img-"+index} className="content_img_slider">
                                        <div className="content-info-slide">
                                            <span className="distric c-white">{e.district.toUpperCase()}</span>
                                            <span className="project c-white">{e.project}</span>
                                            <div className={`btn-radio c-white b-${e.project}`} onClick={()=>{this.moveTo("#slider-main","#formulario",e.project)}}>
                                                <span target="_blank" className="c-white">{e.about}</span>
                                                {e.about == "Vendido" ? null : <img src={require("../../assets/images/icons/signal-right.svg").default} alt=""></img> }
                                            </div>
                                        </div>
                                        <picture className="expand-img">
                                            <source media="(min-width: 600px)" srcSet={e.img}></source>
                                            <img alt={"edificio-"+e.project} className="img" src={e.imgMovil}></img>
                                        </picture>
                                    </div>
                                )
                            })
                        }
                    </Slider>
                    <div className="content-select-slider">
                    {
                        this.props.data.sliderMain.map((e,index)=>{
                        return( 
                                <div key={"selectores-"+index} className={`item ${this.state.activeSlider == index ? "active" : ""}`}
                                    onClick={(e)=>{this.changeSlider(e,index)}}>
                                    <div className={`line ${e.project}`}></div>
                                    <span className="district">{e.district.toUpperCase()}</span>
                                    <span className="project">{e.project}</span>
                                </div>
                        )})
                    }
                    </div>
                </section>
                <section className="project" id="projects">
                    <h2 className="title title-project">Nuestros <strong>Proyectos</strong></h2>
                </section>
                <section className="information">
                    <h2 className="title"><strong>Entérate de más</strong></h2>
                    <div className="banner">
                        <div className="circle circle-1"></div>
                        <div className="circle circle-2"></div>
                        <img className="doglover" id="doglover" src={require("../../assets/images/icons/persona.png")} alt=""></img>
                        <img className="phone" id="phone" src={require("../../assets/images/icons/phone.png")} alt=""></img>
                        <h3 className="title">El depa de tus sueños</h3>
                        <span className="sub-title">La facilidad de separar tu depa desde donde estés.</span>
                        <a href="https://xn--eldepadetussueos-kub.pe/" className="btn-radio c-white">
                                Saber más
                        </a>
                        <img className="laptop" id="laptop" src={require("../../assets/images/icons/laptop.png")} alt=""></img>
                    </div>
                    <div className="buttons">
                        <div className="info_button b-primary">
                            <div className="comentario"></div>
                            <span className="title-button c-white">Preguntas frecuentes</span>
                            <a target="_blank" href="https://www.cosapiinmobiliaria.com.pe/preguntas-frecuentes" className="button"><div className="add b-primary"></div></a>
                        </div>
                        <div className="info_button b-primary">
                            <div className="users"></div>
                            <span className="title-button c-white">Propietarios</span>
                            <a href="https://www.cosapiinmobiliaria.com.pe/atencion-al-propietario" className="button"><div className="add b-primary"></div></a>
                        </div>
                        <div className="info_button whatsapp b-yellow">
                            <div className="content-info-hidden">
                                <img className="hand" src={require("../../assets/images/icons/hand.png")} alt=""></img>
                                <img className="whatsapp" src={require("../../assets/images/icons/whatsapp.png")} alt=""></img>
                                <span className="title-button c-white">Contact center Whatsapp</span>
                                <span className="sub-title-button c-white">Comunícate con nosotros</span>
                                <a target="_blank" href="https://wa.link/4hxmi2" className="button"><div className="add b-yellow"></div></a>
                            </div>
                        </div>
                    </div>
                    <div className='banner-family'>
                        <picture>
                            <source media="(min-width: 900px)" srcSet={require("../../assets/images/icons/banner-web.png")}></source>
                            <img className='' src={require('../../assets/images/icons/banner-mobile.png')} alt=""></img>
                        </picture>
                        <p className='paragraph'>
                        ¡Refiérenos a tu familia y amigos <br/><strong>y gana una Gifcard!</strong>
                        </p>
                        <a target="_blank" href="/referidos" className="button"><div className="add b-primary"></div></a>
                    </div>
                </section>
                <section className="formulario container" id="formulario">
                    <h2 className="title">¡Estás a un paso de alcanzar tu sueño!<br/><strong>Agenda una asesoría personalizada</strong></h2>
                    <Formulario modal={this.activeModal}></Formulario>
                </section>
            </main>
        )
    }
}