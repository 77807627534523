import { connect } from 'react-redux';
import React, { Component } from 'react';
import {active, close } from '../../data/modal'

class ModalPoliticas extends Component {
    constructor(props){
        super(props)
    }
    render() {
        return (
        <div className={`modal ${this.props.value ? "active":""}`}>
            <div className="content-modal">
                <span>Políticas de privacidad</span>
                <p>Usted puede ejercer sus derechos que como titular de datos personales le asisten (acceso, rectificación, cancelación, oposición, información o revocación). Para tal efecto, por favor escríbanos al siguiente correo <a href="mailto:informes@cosapiinmobiliaria.com.pe?Subject=Privacidad%20de%20datos">informes@cosapiinmobiliaria.com.pe</a> con la referencia: “Privacidad de datos”.</p>
                <div className="close" onClick={()=>{this.props.close()}}></div>
            </div>
        </div>
        );
    }
}
const mapStateToProps = state => {
    return {value : state.modalSlice.value}
}

export default connect( mapStateToProps,{active,close})(ModalPoliticas)