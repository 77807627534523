import * as Yup from 'yup';

export const BaseInterestedConstraints = {
    fname: Yup.string()
    .matches(/^[A-Za-z]+$/, 'El nombre no debe contener números').required(),
    lname: Yup.string()
    .matches(/^[A-Za-z]+$/, 'El nombre no debe contener números').required(),
    email: Yup.string()
        .email()
        .required(),
    telephone: Yup.string()
        .matches(/[\+?\-?0-9]{7,}/)
        .required(),
}

export const ReferecialSchema = (values) => Yup.object().shape({
    fname: Yup.string().matches(/^[A-Za-z]+$/, 'El nombre no debe contener números').required(),
    lname: Yup.string().matches(/^[A-Za-z]+$/, 'El nombre no debe contener números').required(),
    email: Yup.string().email().required(),
    document: Yup.string().required().max(8),
    telephone: Yup.string().required().max(9),
    terms: Yup.boolean().oneOf([true], 'Debe aceptar los términos y condiciones'),
    fname_refer: Yup.string().required(),
    lname_refer: Yup.string().required(),
    email_refer: Yup.string().email().required(),
    document_refer: Yup.string().required().max(8),
    telephone_refer: Yup.string().required().max(9),
    project: Yup.string().required(),
});

export const MultiplyInterestedSchema = (values) => Yup.object().shape({
    project: Yup.string().required(),
    dorms: Yup.string().required(),
    fname:Yup.string().matches(/^[A-Za-z]+$/, 'El nombre no debe contener números').required(),
    lname:Yup.string().matches(/^[A-Za-z]+$/, 'El nombre no debe contener números').required(),
    telephone: Yup.string().required().max(9),
    email:Yup.string().email().required(),
    time_to_contact: Yup.string().required(),
    terms:Yup.boolean().oneOf([true], 'Debe aceptar los términos y condiciones'),
});

export const BaseProjectSubscribeValidatonSchema = (values) => Yup.object()
    .shape({
        ...BaseInterestedConstraints,
        project: Yup.string()
            .required(),
        terms: Yup.bool()
            .oneOf([true]).required()
    })

export const WorkerBenefitsSubscribeValidatonSchema = (values) => Yup.object()
    .shape({
        ...BaseInterestedConstraints,
        project: Yup.string()
            .required(),
        working_area: Yup.string()
            .required(),
        terms: Yup.bool()
            .oneOf([true]).required()
    })

export const HomeContactValidatonSchema = (values) => Yup.object()
    .shape({
        ...BaseInterestedConstraints,
        motive: Yup.string()
            .required(),
        project: Yup.string()
            .required(),
        terms: Yup.bool()
            .oneOf([true]).required()
    })

export const FAQContactValidatonSchema = (values) => Yup.object()
    .shape({
        ...BaseInterestedConstraints,
        message: Yup.string()
            .required(),
        terms: Yup.bool()
            .oneOf([true]).required()
    })

export const BaseValidatonSchema = (values) => Yup.object()
.shape({
    ...BaseInterestedConstraints,
    terms: Yup.bool()
        .oneOf([true]).required()
})