import React, { Component } from 'react'
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom'
import Navbar from './component/navbar/navbar'
import Inicio from './pages/inicio/inicio'
import {dataInicio} from './data/inicio'
import Footer from './component/footer/footer'
import ModalPoliticas from './component/modalPoliticas/modalPoliticas'
import Loader from './component/loader/loader'
import Multiproducto from './pages/Multiproducto/multiproducto'
import { Error404 } from './pages/error404/error404'
// import Page from './component/range/page'
export default class Routes extends Component {
  componentDidMount(){
    function esDispositivoiOS(){
        var userAgent = window.navigator.userAgent;
        let typeDevice = (/iPhone|iPod|iPad|Mac/.test(userAgent));
        if(typeDevice){
            document.querySelector("#root").classList.add("IOS")
        }
    }
    esDispositivoiOS()
  }  
  render() {
    return (
        <Router>
            {
                process.env.NODE_ENV == 'development' ? null : <Loader></Loader> 
            }
            <Navbar></Navbar>
            <ModalPoliticas></ModalPoliticas>
            <Switch>
                <Route exact path="/">
                    <Multiproducto/>
                </Route>
                <Route exact path="/*">
                    <Error404/>
                </Route>
            </Switch>
            <Footer></Footer>
        </Router>
    )
  }
}

