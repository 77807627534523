import React, { Component } from 'react'
import {FormContainer, setInputProps, checkableBoolProps} from "./../common/forms/Form"
import { HomeContactValidatonSchema } from '../common/forms/constraints/ValidatonSchemas'
import { PROJECTS_CRM, PROJECTS_DATA, PROJECT_SELLERS } from '../../data/projects'
import {SperantService} from "../common/Http/SperantService"
import Swal from 'sweetalert2'
import { connect } from 'react-redux'
import { active } from '../../data/modal'
class Formulario extends Component {
    constructor(props){
        super(props)
        this.submitForm = this.submitForm.bind(this)
        this.sperantService = new SperantService()
        this.closeModal = this.closeModal.bind(this)
        this.state = {
            activeModal:false,
        }
    }
    onSuccess = seller_phone =>{
        this.setState({activeModal:true, sellerPhone: seller_phone})
    }
    onError = _ =>{
        Swal.fire({
            title: '',
            text: '¡Error! Intentelo más tarde',
            icon: 'error',
            confirmButtonText: 'Aceptar',
            confirmButtonColor: 'var(--primary)'
        })
    }
    
    submitForm(values, { setSubmitting, resetForm }) {
        const project = PROJECTS_DATA[values.project]
        
        Object.assign(values, {
            project: project,
            input_channel_ids: project.input_channels.web,
            source_id: project.source_ids.web
        })

        this.sperantService.environment(project.environment)
        this.sperantService.createClient(values)
        .then(({data: {client}})=>{
            let seller_phone = null

            if(values.project !== "épiqe" && values.project !=="muvin"){
                seller_phone = client.seller[0].phone.replace(/\s/g, '')
                                    .replace(/\+-/g, '')
                                    .replace(/\(/g, '')
                                    .replace(/\)/g, '')
                                    .replace(/\+51/g, '').split("/")[0]
    
            }
            this.onSuccess(seller_phone)
            window.fbq && window.fbq('track', 'Lead', {project: project.name})
            resetForm()
        })
        .catch(e=>this.onError)
    }
    initialValues = {
        fname: "",
        lname: "",
        email: "",
        telephone: "",
        motive: "",
        project: "",
        terms: false,
    }
    closeModal(){
        this.setState({activeModal:false})
    }
    render() {
        return (
            <FormContainer
            initialValues={this.initialValues} validationSchema={HomeContactValidatonSchema} onSubmit={this.submitForm}
        >
            {form => {const {handleSubmit, isSubmitting} = form;
            // {console.error(form.errors)}
            return(
            <form className="form" onSubmit={handleSubmit}>
                <div className={`popup ${this.state.activeModal ? "active" : ""}`}>
                    <div className="content-popup">
                        <div className="btn-close" onClick={()=>{this.closeModal()}}><img src={require("../../assets/images/icons/btn-close.svg").default}></img></div>
                        <img className="background desktop" src={require("../../assets/images/icons/background-modal.png")} alt=""></img>
                        <img className="background movil" src={require("../../assets/images/icons/background-modal-movil.png")} alt=""></img>
                        <img className="icon-check" src={require("../../assets/images/icons/check_icon.svg").default} alt=""></img>
                        <span className="title">¡Gracias!</span>
                        <span className="sub-title">Dentro de poco un asesor se pondrá en contacto contigo.</span>
                        {this.state.sellerPhone && <>
                        <span className="sub-title bold">También puedes contactarlo aquí</span>
                        <div className="contact">
                            <a href={`https://wa.me/51${this. n.sellerPhone}`} target="_blank"><img className="icon icon-whatsapp" src={require("../../assets/images/icons/whatsapp-white.svg").default} alt=""></img></a>
                            <a href={`tel:${this.state.sellerPhone}`} target="_blank"><img className="icon" src={require("../../assets/images/icons/call-white.svg").default} alt=""></img></a>
                        </div>
                        </>}
                    </div>
                </div>
                <div className="content-inputs">
                    <input placeholder="Nombre" {...setInputProps("fname", "", form)}></input>
                    <input placeholder="Apellidos" {...setInputProps("lname", "", form)}></input>
                    <input placeholder="Telefóno" {...setInputProps("telephone", "", form)}></input>
                    <input placeholder="Correo electrónico" {...setInputProps("email", "", form)}></input>
                    <select {...setInputProps("project", "", form)}>
                        <option value="">Selecciona tu proyecto</option>
                        {Object.keys(PROJECTS_DATA).map((key, i)=>
                            PROJECTS_DATA[key].leadCreationAllowed && <option key={i} value={key}>{PROJECTS_DATA[key].name}</option>
                        )}
                    </select>
                    <select {...setInputProps("motive", "", form)}>
                        <option value="">Motivo de compra</option>
                        <option value="Primera Vivienda">Primera Vivienda</option>
                        <option value="Inversión">Inversión</option>
                    </select>
                </div>
                <div className="politicas">
                    <input id="terms" type="checkbox" {...checkableBoolProps("terms", "", form)}></input>
                    <label htmlFor="terms" className="label-accept"><div className="check"></div></label>
                    <span className="terms">Acepto las <a target="_blank" href={require("../../assets/files/Politica-de-privacidad.pdf")}>políticas de privacidad</a></span>
                </div>
                <button type="submit" className="btn-submit btn-radio c-white b-primary" disabled={isSubmitting}>{isSubmitting ? 'Enviando...' : 'Enviar'}</button>
            </form>)}}
            </FormContainer>
        )
    }
}
function mapStateProps(state){
    return{
        value:state.modalSlice.value
    }
}
export default connect(mapStateProps,{active})(Formulario)