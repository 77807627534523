import {PROJECT_FLATS, PROJECT_LOCATIONS, PROJECT_PHASES} from "./projects"

export const SLIDER_HOME = [
    {
        img: require("../assets/images/sliderMain/ativa-desktop.png"),
        imgMovil: require("../assets/images/sliderMain/ativa-movil.png"),
        ...PROJECT_LOCATIONS.ativa,
        project: "Ativa",
        index: 1,
        about: "Saber más"
    },
    {
        img: require("../assets/images/sliderMain/Muvin.png"),
        imgMovil: require("../assets/images/sliderMain/Muvin_mobile.png"),
        ...PROJECT_LOCATIONS.muvin,
        project: "Muvin",
        index: 2,
        about: "Saber más"
    },
    
    {
        img: require("../assets/images/sliderMain/Nesta.png"),
        imgMovil: require("../assets/images/sliderMain/Nesta_mobile.png"),
        ...PROJECT_LOCATIONS.nesta,
        project: "Nesta",
        index: 4,
        about: "Saber más"
    },
    {
        img: require("../assets/images/sliderMain/Sente.png"),
        imgMovil: require("../assets/images/sliderMain/Sente_mobile.png"),
        ...PROJECT_LOCATIONS.sente,
        project: "Sente",
        index: 1,
        about: "Saber más"
    },
    {
        img: require("../assets/images/sliderMain/Prana.png"),
        imgMovil: require("../assets/images/sliderMain/Prana_mobile.png"),
        ...PROJECT_LOCATIONS.prana,
        project: "Prana",
        index: 0,
        about: "Vendido"
    },
    {
        img: require("../assets/images/sliderMain/Duplo.png"),
        imgMovil: require("../assets/images/sliderMain/Duplo_mobile.png"),
        ...PROJECT_LOCATIONS.duplo,
        project: "Duplo",
        index: 0,
        about: "Vendido"
    },
    {
        img: require("../assets/images/sliderMain/Velia.png"),
        imgMovil: require("../assets/images/sliderMain/Velia_mobile.png"),
        ...PROJECT_LOCATIONS.velia,
        project: "Velia",
        index: 0,
        about: "Vendido"
    },
]

export const PROJECT_CARDS = [
    {
        project: "Midgo",
        imgBrand: "brand-midgo",
        imgProject: require("../assets/images/render/render-midgo.jpg"),
        altimgProject: "Fachada Proyecto Midgo Departamentos en Lince",
        imgBrand: require("../assets/images/multiproducto/brand-midgo.png"),
        phases: PROJECT_PHASES.midgo,
        stages: "Lanzamiento",
        stage: "Lanzamiento",
        frase: "A pasos del  <strong>C.C. Risso</strong>",
        valuation: "",
        ...PROJECT_LOCATIONS.midgo,
        dorms: "2 y 3",
        dormsShow: "2 y 3 Dorms",
        meters: "36m² - 72m²",
        views: {desde:"453",hasta:"443"},
        tendency: "Tendencia",
        all: "all",
        link: "",
        index: 1,
        button:"Registrate aquí",
        altBrand:"Ativa Departamentos en Surco"
    },
    {
        project: "Momen",
        imgBrand: "brand-momen",
        imgProject: require("../assets/images/render/render-momen.jpg"),
        altimgProject: "Fachada Proyecto Momen Departamentos en Breña",
        imgBrand: require("../assets/images/multiproducto/brand-momen.svg").default,
        phases: PROJECT_PHASES.momen,
        stages: "Preventa",
        stage: "Preventa",
        frase: "A pasos de <strong>C.C. La Rambla Brasil</strong>",
        valuation: "",
        ...PROJECT_LOCATIONS.momen,
        dorms: "2 y 3",
        dormsShow: "2 y 3 Dorms",
        meters: "52m² - 71m²",
        views: {desde:"453",hasta:"443"},
        tendency: "Tendencia",
        all: "all",
        link: "",
        index: 1,
        button:"Registrate aquí",
        altBrand:"Ativa Departamentos en Surco"
    },
    {
        project: "Ativa",
        imgBrand: "brand-ativa",
        imgProject: require("../assets/images/render/ativa.png"),
        altimgProject: "Fachada Proyecto Ativa Departamentos en Surco",
        imgBrand: require("../assets/images/multiproducto/ATIVA.png"),
        phases: PROJECT_PHASES.ativa,
        stages: "Preventa",
        stage: "Preventa",
        frase: "A pasos del <strong>C.C. El Polo</strong>",
        valuation: "",
        ...PROJECT_LOCATIONS.ativa,
        dorms: "1, 2 y 3",
        dormsShow: "1, 2 y 3 Dorms",
        meters: "40m² - 71m²",
        views: {desde:"453",hasta:"443"},
        tendency: "Tendencia",
        all: "all",
        link: "https://ativa.cosapiinmobiliaria.com.pe/",
        index: 2,
        button:"Registrate aquí",
        altBrand:"Ativa Departamentos en Surco"
    },
   
    {
        project: "Muvin",
        imgBrand: "brand-muvin",
        imgProject: require("../assets/images/render/Muvin_dia.png"),
        altimgProject:"Fachada Proyecto Muvin Departamentos en Lince",
        imgBrand: require("../assets/images/multiproducto/MUVIN.png"),
        phases: PROJECT_PHASES.muvin,
        stages: "En Construcción",
        stage: "En Construcción",
        frase: "A unos pasos de <strong>San Isidro</strong>",
        valuation: "Éxito en ventas",
        ...PROJECT_LOCATIONS.muvin,
        ...PROJECT_FLATS.muvin,
        dorms: "1, 2 y 3",
        dormsShow: "1, 2 y 3 Dorms",
        meters: "45m² - 70m²",
        views: {desde:"123",hasta:"234"},
        tendency: "Tendencia",
        all: "all",
        link: "https://muvin.cosapiinmobiliaria.com.pe/",
        index: 2,
        button:"Registrate aquí",
        altBrand:"Muvin Departamentos en Lince"
    },
    {
        project: "Nesta",
        imgBrand: "brand-nesta",
        imgProject: require("../assets/images/render/render-nesta.webp"),
        imgBrand: require("../assets/images/multiproducto/nesta.png"),
        altimgProject:"Fachada Proyecto Nesta Departamentos en Jesus Maria",
        phases: PROJECT_PHASES.nesta,
        stages: "Preventa Construcción",
        stage: "Preventa",
        frase:"Vive frente al <strong>Campo de Marte</strong>",
        valuation: "Éxito en ventas",
        ...PROJECT_LOCATIONS.nesta,
        ...PROJECT_FLATS.nesta,
        dorms: "1, 2 y 3",
        dormsShow: "1, 2 y 3 Dorms",
        meters: "40m² - 109m²",
        views: {desde:"715",hasta:"1524"},
        tendency: "Tendencia",
        all: "all",
        link: "https://nesta.com.pe/",
        index: 4,
        button:"Registrate aquí",
        altBrand:"Nesta Departamentos en Jesús María"
    },
    {
        project: "Prana",
        imgBrand: "brand-prana",
        imgProject: require("../assets/images/render/render-prana.jpg"),
        stages: "Vendido",
        stage: "Vendido",
        valuation: "",
        ...PROJECT_LOCATIONS.prana,
        dorms: "",
        dormsShow: "2 y 3 Dorms",
        views: "1",
        tendency: "Tendencia",
        all: "all",
        link: "",
        index: 6,
        button:"Conoce el proyecto",
    },
    {
        project: "Duplo",
        imgBrand: "brand-duplo",
        imgProject: require("../assets/images/render/render-duplo.jpg"),
        stages: "Vendido",
        stage: "Vendido",
        valuation: "",
        ...PROJECT_LOCATIONS.duplo,
        dorms: "Tendencia",
        dormsShow: "2 y 3 Dorms",
        views: "1",
        tendency: "Tendencia",
        all: "all",
        link: "",
        index: 7,
        button:"Conoce el proyecto",
    },
    {
        project: "Velia",
        imgBrand: "brand-velia",
        imgProject: require("../assets/images/render/render-velia.jpg"),
        stages: "Vendido",
        stage: "Vendido",
        valuation: "",
        ...PROJECT_LOCATIONS.velia,
        dorms: "",
        dormsShow: "1, 2 y 3 Dorms",
        views: "1",
        tendency: "Tendencia",
        all: "all",
        link: "",
        index:8,
        button:"Conoce el proyecto",
    },
]

export const dataInicio = {
    sliderMain: SLIDER_HOME,
    cardsFilter: PROJECT_CARDS
}