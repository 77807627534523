export class BaseEntity {
  _parseAttrs(target, data) {
    Object.entries(data).map(
      ([key, value]) => (target[this._snakeToCamel(key)] = value)
    )
  }

  _snakeToCamel = (str) =>
    str
      .toLowerCase()
      .replace(/([-_][a-z])/g, (group) =>
        group.toUpperCase().replace("-", "").replace("_", "")
      )
}