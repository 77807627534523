import { useEffect, useState } from "react"
import OwlCarousel from "react-owl-carousel2"
import { FormContainer } from "../../component/common/forms/Form"
import { dataInicio, PROJECT_CARDS} from "../../data/inicio"
import { FormMultiproducto } from "./formMultiproducto"
import { moveTo,events,offset,options, initAnimation, settingsCards } from "./script"

import $ from "jquery"
import MediaQuery from "react-responsive"
import Slider from "react-slick";
import ScrollReveal from 'scrollreveal';
import './multiproducto.scss'
import { useDispatch } from "react-redux"
import { changeName } from "./multiproductoSlice"
let sr = ScrollReveal()
export default function Multiproducto(){
    const [updateForm,setUpdateForm] = useState(false)
    useEffect(()=>{
        window.scrollTo(0,0)
        if(window.innerWidth > 500){
            initAnimation()
        }
        sr.reveal(".hand-left",{opacity:1,beforeReveal: function (el) {
            el.classList.add("active")
        } })
        sr.reveal(".hand-right",{opacity:1,beforeReveal: function (el) {
            el.classList.add("active")
        }})
    },[updateForm])
    return (
        <main className="multiproducto referidos">
            <HeaderMultiproduct/>
            <section className='section-dreams'>
                <img className='hand hand-left' src={require('../../assets/images/multiproducto/hand-man.png')} alt="mano con una rosa"></img>
                <img className='hand hand-right' src={require('../../assets/images/multiproducto/hand-woman.png')} alt="mano recibiendo una rosa"></img>
                <div className='content' id="banner-hands">
                    <div className="mini-ball mini-ball-1"></div>
                    <div className="mini-ball mini-ball-2"></div>
                    <div className='container-multiproducto' id="container-multiproducto">
                        <FormMultiproducto setUpdateForm={setUpdateForm}/>
                    </div>
                </div>
            </section>
            
        </main>
    )
}



function HeaderMultiproduct(){
    const [position,setPosition] = useState(0);
    const [activeFase,setFaseActive] = useState(0);
    const dispatch = useDispatch();
    const [listBrands, setListBrands] = useState([
        {name:"midgo",img:require("../../assets/images/multiproducto/brand-midgo.png"),alt:"Ativa Departamentos en Surco"},
        {name:"momen",img:require("../../assets/images/multiproducto/brand-momen.svg").default,alt:"Ativa Departamentos en Surco"},
        {name:"ativa",img:require("../../assets/images/multiproducto/ATIVA.png"),alt:"Ativa Departamentos en Surco"},
        {name:"muvin",img:require("../../assets/images/multiproducto/MUVIN.png"),alt:"Muvin Departamentos en Lince"},
        {name:"nesta",img:require("../../assets/images/multiproducto/nesta.png"),alt:"Nesta Departamentos en Jesus Maria"},
    ])
    const moveSlider = (e) => {
        $(`.brand-${e}`).addClass("active").siblings().removeClass("active")
        let owl = $("#carousel-target").owlCarousel()
        owl.trigger('to.owl.carousel', [e, 300]);
    }
    const moveToForm = ()=>{
    }
    const changeFase =(project,fase)=>{
        if(fase){
            $(`.${project} .stage`).removeClass("active")
            $(`.${project} .${fase}`).addClass("active")
        }
    }
    const selectPromo = (project,dorm)=>{
        dispatch(changeName(project))
        let delay =  setInterval(() => {
            try {
                moveTo(".link.button","#contacto")
                let select_project = document.getElementsByName("project")[0]
                let select_dorms = document.getElementsByName("dorms")[0]
                select_project.value = project;
                select_dorms.value = dorm;
                select_project.dispatchEvent(new Event("change",{bubbles: true}))
                select_dorms.dispatchEvent(new Event("change",{bubbles: true}))
                clearInterval(delay)
            } catch (err) {
                console.error(err)
            }
        }, 300);
    }
    return(
        <>
            <section className=' section-banner'>
                <picture className="expand-img">
                    <source media="(min-width: 600px)" srcSet={require('../../assets/images/multiproducto/slider_header.png')}></source>
                    <img className='expand-img' src={require('../../assets/images/multiproducto/slider_mobile.png')} alt="Cosapi Inmobiliaria Departamentos en Lima"></img>
                </picture>
                <img className='key-img moveDown' src={require('../../assets/images/multiproducto/key.png')} alt="Cosapi Inmobiliaria la llave de tu departamento en Lima"></img>
            </section>
            <div className='container-multiproducto'>
                <h1 className="text-center mt-4-xl mt-3-md mb-md-2 mb-sm-1 title-list-brands">Selecciona el <strong>proyecto de tu interés</strong></h1>
                <div className='list-brands'>
                    <MediaQuery minWidth={700}>
                    {
                        listBrands.map((item, index) => {
                            return(
                                <div className={`item-brand-${item.name} brand brand-${index} ${index == 0 ? "active":""}`} key={index+"listbrands"} onClick={()=>{moveSlider(index)}}>
                                    <img className="brand-img" src={item.img} alt={item.alt}></img>
                                </div>
                            )
                        })
                    }
                    </MediaQuery>
                    <MediaQuery maxWidth={699}>
                        <Slider {...settingsCards} >
                            {
                                listBrands.map((item, index) => {
                                    return(
                                        <div className={`item-brand-${item.name} brand brand-${index} ${index == 0 ? "active":""}`} key={index+"listbrands"} onClick={()=>{moveSlider(index)}}>
                                            <img className="brand-img" alt={item.alt} src={item.img}></img>
                                        </div>
                                    )
                                })
                            }
                        </Slider>
                    </MediaQuery>
                </div>
                <section className='section-target-slider container'>
                    <OwlCarousel options={options} events={events} className="owl-carousel" id="carousel-target">
                        {
                            dataInicio.cardsFilter.map((item, index) => {
                                if(item.stage != "Vendido"){
                                    return(
                                        <div className="target" key={index+"datainicio"}>
                                            <div className='content-img'>
                                                <MediaQuery maxWidth={499}>
                                                    <span className='frase' dangerouslySetInnerHTML={{__html:item.frase ? item.frase : null}}></span>
                                                </MediaQuery>
                                                <img className='img-project' src={item.imgProject} alt={item.altimgProject}></img>
                                            </div>
                                            <div className='info'>
                                                <div className='header' id={"header-"+index}>
                                                    <div className='content-brand'>
                                                        <img className='' src={item.imgBrand} alt={item.altBrand}></img>
                                                    </div>
                                                    <MediaQuery minWidth={500}>
                                                        <span className='frase' dangerouslySetInnerHTML={{__html:item.frase ? item.frase : null}}></span>
                                                    </MediaQuery>
                                                </div>
                                                <div className='data d-flex'>
                                                    <div className='dorms d-flex align-center'>
                                                        <div className='icon d-flex align-center justify-center'>
                                                            <div className="mask place-2"></div>
                                                        </div>
                                                        <div className='d-flex f-column'>
                                                            <span className='title-data' dangerouslySetInnerHTML={{__html:item.district }}>
                                                            </span>
                                                            <span className='sub-title' dangerouslySetInnerHTML={{__html:item.address}}></span>
                                                        </div>
                                                    </div>
                                                    <div className='meters d-flex align-center'>
                                                        <div className='icon d-flex align-center justify-center'>
                                                            <div className="mask croquis"></div>
                                                        </div>
                                                        <div className='d-flex f-column'>
                                                            <span className='title-data'>
                                                                { item.meters}
                                                            </span>
                                                            <span className='sub-title'>METRAJE</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={`stages ${item.project}`} id={"stage-"+index}>
                                                    {
                                                        item.project === "Épiqe" && item.phases ? 
                                                            <div className='stage d-flex justify-center align-center active' key={index+"phases"}>
                                                                <span className='title'>{item.phases[0].stage}</span>
                                                                <span className='sub-title'></span>
                                                            </div>
                                                        : item.phases?.map((e, index) => {
                                                            return(
                                                                <div className={`stage d-flex justify-center align-center ${e.name.replace(" ","-")} ${activeFase == index ? "active":""}`} key={index+"phases"} onClick={()=>{
                                                                    changeFase(item.project,e.name.replace(" ","-"));
                                                                }}>
                                                                    <span className='title'>{e.stage}</span>
                                                                    <span className='sub-title'>{e.name}</span>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                                {
                                                    <span className='title-cuotes c-letter mb-1 d-flex'>Selecciona tu <strong>cuota mensual</strong></span>
                                                }
                                                <div className={`content-list-dorms ${item.project}`}>
                                                    {
                                                        item.phases.map((fase,index)=>{
                                                            return(
                                                                <div className={`stage ${fase.name.replace(" ","-")} ${index==0?"active":""}`}>
                                                                    {
                                                                        fase.dorms.map((dorm)=>{
                                                                            return(
                                                                                <div className='link' id={"link-"+index} onClick={()=>{selectPromo(item.project+`${fase.name ? " - "+fase.name : ""}`,dorm.value)}}>
                                                                                    {dorm.text}
                                                                                    <img className='icon-right' src={require('../../assets/images/icons/right.svg').default}  alt=""></img>
                                                                                </div>
                                                                            )
                                                                        })
                                                                    }
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            
                                                <span className='nota-legal c-letter'>
                                                    *Cuotas referenciales sujetas a evaluación crediticia.
                                                </span>
                                            </div>
                                        </div>
                                    )
                                }
                            })
                        }
                        
                    </OwlCarousel>
                </section>
            </div>
        </>
    )
}