import {createSlice} from '@reduxjs/toolkit'

const initialState = {
    nameProject:"",
}
export const multiproductoSlice = createSlice({
    name:"multiproductoSlice",
    initialState,
    reducers:{
        changeName:(state,value)=>{
            state.nameProject = value.payload
        }
    }
}) 

export const { changeName } = multiproductoSlice.actions
export default multiproductoSlice.reducer 