import {createSlice} from '@reduxjs/toolkit'

const initialState = {
    data:[
        
    ]
}
export const inversionStore = createSlice({
    name:"inversion",
    initialState,
    reducers:{

    }
}) 

export default inversionStore.reducer 